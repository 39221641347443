"use strict";
class UserOrdering {
    constructor(submitButton, closeButton, errorNotice, manualInputItemsObject, checkboxes, userId, moduleId) {
        var _a, _b;
        Object.defineProperty(this, "submitButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: submitButton
        });
        Object.defineProperty(this, "closeButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: closeButton
        });
        Object.defineProperty(this, "errorNotice", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: errorNotice
        });
        Object.defineProperty(this, "manualInputItemsObject", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: manualInputItemsObject
        });
        Object.defineProperty(this, "checkboxes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: checkboxes
        });
        Object.defineProperty(this, "userId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: userId
        });
        Object.defineProperty(this, "moduleId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: moduleId
        });
        Object.defineProperty(this, "savingLang", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'Saving'
        });
        Object.defineProperty(this, "buttonText", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.buttonText = (_a = this.submitButton.textContent) !== null && _a !== void 0 ? _a : 'Save';
        this.savingLang = (_b = this.submitButton.getAttribute('data-js-saving-lang')) !== null && _b !== void 0 ? _b : this.savingLang;
        if (wpApiSettings) {
            this.submitListener();
        }
    }
    submitListener() {
        this.submitButton.addEventListener('click', (event) => {
            event.preventDefault();
            let values = {};
            this.checkboxes.forEach((checkbox) => {
                values[checkbox.value] = checkbox.checked;
            });
            this.patchUser(values);
        });
    }
    patchUser(values) {
        var _a;
        this.handleBeforeSave();
        const endpoint = `${wpApiSettings.root}wp/v2/users/${this.userId}`;
        fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-WP-NONCE': (_a = wpApiSettings === null || wpApiSettings === void 0 ? void 0 : wpApiSettings.nonce) !== null && _a !== void 0 ? _a : '',
            },
        })
            .then(response => {
            if (!response.ok) {
                throw new Error('Failed to fetch existing user data');
            }
            return response.json();
        })
            .then(data => {
            var _a, _b;
            let manualInputs = ((_a = data.meta) === null || _a === void 0 ? void 0 : _a.manualInputs) || {};
            manualInputs[this.moduleId] = values;
            return fetch(endpoint, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'X-WP-NONCE': (_b = wpApiSettings === null || wpApiSettings === void 0 ? void 0 : wpApiSettings.nonce) !== null && _b !== void 0 ? _b : '',
                },
                body: JSON.stringify({
                    meta: {
                        manualInputs: manualInputs,
                    }
                }),
            });
        })
            .then(() => {
            this.handleSuccessfullSave(values);
        })
            .catch(error => {
            console.error('Error:', error);
            this.handleFailedSave();
        });
    }
    handleBeforeSave() {
        this.submitButton.disabled = true;
        this.closeButton.disabled = true;
        console.log(this.closeButton);
        this.submitButton.textContent = this.savingLang + '...';
    }
    handleFailedSave() {
        this.errorNotice.classList.remove('u-display--none');
        this.submitButton.disabled = false;
        this.closeButton.disabled = false;
        this.submitButton.textContent = this.buttonText;
    }
    handleSuccessfullSave(values) {
        this.submitButton.disabled = false;
        this.closeButton.disabled = false;
        this.submitButton.textContent = this.buttonText;
        this.showOrHideItemsBasedOnSaved(values);
        this.closeButton.click();
    }
    showOrHideItemsBasedOnSaved(values) {
        for (const [key, element] of Object.entries(this.manualInputItemsObject)) {
            if (!(key in values)) {
                continue;
            }
            if (values[key]) {
                element.classList.remove('u-display--none');
            }
            else {
                element.classList.add('u-display--none');
            }
        }
    }
}
document.addEventListener('DOMContentLoaded', function () {
    const privateManualInputs = document.querySelectorAll('[data-js-manual-input-user-ordering]');
    privateManualInputs.forEach(privateManualInput => {
        const userId = privateManualInput.getAttribute('data-js-manual-input-user');
        const moduleId = privateManualInput.getAttribute('data-js-manual-input-id');
        const submitButton = privateManualInput.querySelector('button[type="submit"]');
        const checkboxes = privateManualInput.querySelectorAll('input[type="checkbox"]');
        const errorNotice = privateManualInput.querySelector('[data-js-manual-input-error]');
        let manualInputItemsObject = {};
        privateManualInput.querySelectorAll('[data-js-item-id]').forEach(item => {
            const itemId = item.getAttribute('data-js-item-id');
            if (itemId) {
                manualInputItemsObject[itemId] = item;
            }
        });
        const closeButton = privateManualInput.querySelector('button[data-js-cancel-save]');
        if (submitButton && closeButton && userId && moduleId && checkboxes.length) {
            new UserOrdering(submitButton, closeButton, errorNotice, manualInputItemsObject, checkboxes, userId, moduleId);
        }
    });
});
